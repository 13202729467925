export default {
  'playingTurn.title': 'Lịch sử đổi lượt chơi',
  'playingTurn.code': 'Mã lượt chơi',
  'playingTurn.remainTurn': 'Số lượt chơi',
  'playingTurn.status': 'Trạng thái',
  'playingTurn.status.id':
    '{status, select, 1 {Chưa sử dụng} 2 {Đã sử dụng} 3 {Còn lượt quét} other {Không xác định}}',
  'playingTurn.status.id.1': 'Chưa sử dụng',
  'playingTurn.status.id.2': 'Đã sử dụng',
  'playingTurn.status.id.3': 'Còn lượt quét',
  'playingTurn.total.code': 'Tổng số mã',
  'playingTurn.add.code': 'Thêm mã lượt chơi',
  'playingTurn.edit.code': 'Chỉnh sửa mã lượt chơi',
  'playingTurn.add.quantity.code': 'Số lượng mã',
  'playingTurn.add.quantity.play': 'Số lượt chơi của mỗi mã',
  'playingTurn.title.delete': 'Xóa mã',
  'playingTurn.content.delete': 'Bạn có muốn xóa những mã này không',
  'playingTurn.router-name': 'Quản lý mã',
  'playingTurn.title.list': 'Quản lý mã',
  'playingTurn.import.code': 'Tải tệp lên',
  'playingTurn-info.name': 'Thông tin mã',
  'playingTurn.scanningTime': 'Số lượt quét',
  'playingTurn.scanningTime.total': 'Lượt chơi mỗi lần quét',
  'playingTurn.validTime': 'Thời gian hiệu lực',
  'playingTurn.expiredTime': 'Thời gian hết hạn',

  'playingTurn.typeGame': 'Loại trò chơi',
  'playingTurn.typeGame.claw': 'Máy gắp thú',
  'playingTurn.typeGame.vr': 'Game VR',

  'playingTurn.action': 'Hành động',
  'playingTurn.undefined': 'Không xác định',
};
